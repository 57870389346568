<template lang="pug">
  v-container(fluid)
    v-dialog(:value='value', @click:outside='$emit("input", false)', @input='$emit("input", !value)', width='1150')
      v-card(elevation='0')
        v-card-title.headline.grey.lighten-2.v-card-title--custom
          div Редактирование креатива
          v-btn(fab, color='accent', x-small, outlined, @click='$emit("input", false)')
            v-icon close
        v-card-text.mt-5
          .custom-scroll.mt-5(style='max-height: calc(100vh - 250px)')
            v-data-table.v-data-table--custome(
              no-data-text='',
              :headers='headers',
              :items='[creativeClone]',
              :expanded.sync='expanded',
              hide-default-footer,
              show-expand
            )
              template(v-slot:header.count='{ header }')

              template(v-slot:expanded-item='{ headers, item }')
                td(:colspan='headers.length')
                  div(style='max-width: 800px; padding: 15px')
                    v-row
                      v-col
                        .field
                          .field__container
                            .field__text Название
                            v-text-field(
                              v-model='item.name',
                              dense,
                              outlined,
                              hide-details='auto'
                            )

                          .field__container.mt-4
                            .field__text Поставщик поверхностей
                            v-select(
                              :items='getPublishers',
                              :menu-props='{ bottom: true, offsetY: true }',
                              dense,
                              outlined,
                              hide-details,
                              item-value='id',
                              item-text='name',
                              v-model='item.publisherID',
                              readonly,
                              append-icon='mdi-chevron-down'
                            )
                          .field__container.mt-4
                            .field__text Город
                            v-select(
                              :items='cityList',
                              :menu-props='{ bottom: true, offsetY: true }',
                              dense,
                              outlined,
                              hide-details,
                              item-value='id',
                              item-text='name',
                              v-model='item.city',
                              append-icon='mdi-chevron-down'
                            )
                          .field__container.mt-4
                            .field__text Тип
                            v-select(
                              :items='getPlatformTypes',
                              :menu-props='{ bottom: true, offsetY: true }',
                              dense,
                              outlined,
                              hide-details,
                              item-value='id',
                              item-text='name',
                              v-model='item.surfaceType',
                              append-icon='mdi-chevron-down'
                            )
                          .field__container.mt-4
                            .field__text Группа
                            v-combobox(
                              clearable,
                              :items='getGroups',
                              v-model='item.group',
                              dense,
                              outlined,
                              hide-details='auto',
                              append-icon='mdi-chevron-down'
                            )
                      v-col
                        .field
                          .field__container
                            .field__text Ширина
                            v-text-field(
                              v-model='item.width',
                              dense,
                              outlined,
                              hide-details='auto',
                              readonly
                            )
                          .field__container.mt-4
                            .field__text Высота
                            v-text-field(
                              v-model='item.height',
                              dense,
                              outlined,
                              hide-details='auto',
                              readonly
                            )
                          .field__container.mt-4
                            .field__text Рекламируемый бренд
                            v-text-field(
                              v-model='item.brand',
                              dense,
                              outlined,
                              hide-details='auto'
                            )
                          .field__container.mt-4
                            .field__text Теги через пробел
                            v-text-field(
                              v-model='item.tags',
                              dense,
                              outlined,
                              hide-details='auto'
                            )
                          .field__container.mt-4(v-if='creativeClone.remoteURL')
                            .field__text Ссылка на источник
                            v-text-field(
                              v-model='item.remoteURL',
                              dense,
                              outlined,
                              hide-details='auto'
                            )
              template(v-slot:item.preview='{ item }')
                v-tooltip(top)
                  template(v-slot:activator='{ on, attrs }')
                    v-icon(v-bind='attrs', v-on='on')
                      | tv
                  div
                    video(
                      src='/test.mp4',
                      autoplay,
                      loop,
                      style='max-height: 120px; border-radius: 5px'
                    )
              template(v-slot:item.videoDuration='{ item }')
                | {{ item.videoDuration + 'с' }}
              template(v-slot:item.info='{ item }')
                tooltip(text='Технические требования')
                  v-icon
                    | info

          v-card-actions
            v-spacer
            v-btn(color='accent', @click='update', width='190') Сохранить изменения
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Tooltip from '@/components/tooltip';
import loading from "@/mixins/loading_mixin";
import {UPDATE_CREATIVE} from '@/store/const/creatives';

export default {
  name: 'EditItem',
  components: {Tooltip},
  mixins: [loading],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    creative: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.creativeClone = Object.assign({}, this.creative);
        this.expanded = [this.creativeClone];
      }
    },
  },
  computed: {
    ...mapGetters('Map', ['getCities', 'getPublishers', 'getPlatformTypes']),
    ...mapGetters('Creatives', ['getGroups']),
    cityList() {
      return this.getCities.sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  data() {
    return {
      name: '',
      creativeClone: {},
      expanded: [],
      headers: [
        {
          text: 'Превью',
          value: 'preview',
          width: 50,
          sortable: false,
        },
        {
          text: 'Имя',
          value: 'name',
          width: 300,
          sortable: false,
        },
        {
          text: 'Тип',
          value: 'fileMime',
          sortable: false,
        },
        {
          text: 'Длительность',
          value: 'videoDuration',
          sortable: false,
        },
        {
          text: 'ТТ',
          width: 20,
          value: 'info',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
    };
  },
  methods: {
    ...mapActions('Creatives', [UPDATE_CREATIVE]),
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
    async update() {
      let a = this.creativeClone;
      let data = {
        group: a.group,
        id: a.id,
        name: a.name,
        publisherID: a.publisherID,
        fileID: a.fileID,
        ownerID: a.ownerID,
        surfaceType: a.surfaceType,
        city: a.city,
        remoteURL: a.remoteURL
      };
      await this.UPDATE_CREATIVE(data)
      if (this.isError(UPDATE_CREATIVE)) {
        this.errorShow('Не удалось обновить данные')
        return
      }
      this.$notify({
        type: 'success',
        text: 'Изменения успешно сохранены',
      });
      this.$emit('input', false);
      await this.$emit('refresh');
    },
  },
};
</script>

<style scoped></style>
