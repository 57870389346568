<template lang="pug">
  div
    v-dialog(v-model='dialog', width='900')
      template(v-slot:activator='{ on, attrs }')
        tooltip(text='Копировать')
          v-btn.ml-4(fab, x-small, v-bind='attrs', v-on='on', outlined, color='primary')
            v-icon
              | mdi-content-copy
      v-card(v-model='dialog')
        v-card-title.headline.grey.lighten-2.v-card-title--custom
          div Копировать "{{ name }}"
          v-btn(fab, color='accent', x-small, outlined, @click='dialog = false')
            v-icon close
        v-card-text.mt-5
          v-text-field(label='Новое название', v-model='newName', dense, outlined, hide-details)
          .mt-5(style='text-align: right')
            v-btn(@click='copy(item)', elevation='0', color='accent')
              | Копировать
</template>

<script>
import {mapActions} from 'vuex';
import loading from '@/mixins/loading_mixin';
import Tooltip from '@/components/tooltip';
import {CREATE_CREATIVE} from '@/store/const/creatives';

export default {
  name: 'Copy',
  components: {Tooltip},
  mixins: [loading],
  props: {
    name: {
      type: String,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      newName: '',
      dialog: false,
    };
  },
  methods: {
    ...mapActions('Creatives', [CREATE_CREATIVE]),
    async copy({publisherID, fileID, surfaceType, city, group}) {
      let data = {
        name: this.newName,
        publisherID,
        fileID,
        surfaceType,
        city,
        group,
      };
      if (!this.validation()) return;
      await this.CREATE_CREATIVE(data);
      if (this.isError(CREATE_CREATIVE)) {
        this.errorShow('Не удалось скопировать');
        return;
      }
      this.$notify({
        type: 'success',
        title: 'Успешно',
        text: 'Креатив успешно скопирован',
      });
      this.$emit('refresh');
      this.dialog = false;
    },
    validation() {
      if (!this.name) {
        this.errorShow('Пустое название креатива');
        return false;
      }
      if (this.name.length < 4) {
        this.errorShow('Название должно быть более 3 знаков');
        return false;
      }
      return true;
    },
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
  },
};
</script>

<style scoped></style>
