<template lang="pug">
  v-tooltip(top)
    template(v-slot:activator='{ on, attrs }')
      .video-preview(v-bind='attrs', v-on='on', style='width: 28px; height: 24px', @mouseleave='reset()')
        .video-preview__play(v-if='!play', @click='toggle()')
          v-icon
            | play_arrow
        .video-preview__pause(v-if='play', @click='toggle()')
          v-icon
            | pause
        .video-preview__tv
          v-icon
            | tv
    div
      video(:ref='this.refID', :src='previewURL', muted, loop, style='max-height: 120px; border-radius: 5px')
</template>

<script>
export default {
  name: 'VideoPreview',
  props: {
    previewURL: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      play: false,
      played: false,
    };
  },
  computed: {
    refID() {
      return 'video' + this.id;
    },
  },
  methods: {
    reset() {
      if (this.played) {
        this.$refs[this.refID].load();
        this.played = false;
      }
    },
    toggle() {
      this.played = true;
      if (this.play) this.$refs[this.refID].pause();
      else this.$refs[this.refID].play();
      this.play = !this.play;
    },
  },
};
</script>

<style lang="scss">
.video-preview {
  cursor: pointer;

  &:not(:hover) &__play,
  &:not(:hover) &__pause {
    display: none;
  }

  &:hover &__tv {
    display: none;
  }
}
</style>
