<template lang="pug">
	v-container(fluid)
		v-row(justify='end')
			.center-block
				v-text-field(label='Поиск', v-model='search', dense, outlined, hide-details)
				popup-group.ml-4(v-if='getTableData.length', @refresh='refresh()')
				v-dialog(v-model='dialog', width='1150')
					template(v-slot:activator='{ on, attrs }')
						v-btn.ml-4(elevation='0', color='accent', v-bind='attrs', v-on='on') Загрузка креативов
					upload(:multiple='true', @close='dialog = false', @refresh='refresh()')
		v-row.mt-5(v-if='!getTableData.length')
			.text-center.no-data_background.pa-3
				.no-data_text Вы ещё не создали ни одного креатива
		div(v-if='getTableData.length')
			v-row.mt-5(v-for='(creatives, index) in getTableData' :key="index")
				v-scroll-y-reverse-transition
					v-data-table.mt-5.v-data-table--custome.mt-5(
						:headers='headers',
						:items='creatives.data',
						@click.native='toggleTableShow($event, index, creatives)',
						:items-per-page='showedTable.indexOf(index) != -1 ? 100 : 0',
						:hide-default-footer='!creatives.show || creatives.data.length < 11',
						:search='search',
						no-results-text='Нет креативов соответствующих требованиям'
					)
						template(v-slot:header.id='{ header }')
							div
						template(v-slot:header.name='{ header }')
							div(style='display: flex; justify-content: space-between')
								div
									.stat__title {{ header.text }}
									div(style='width: 170px')
										span.stat__total {{ creatives.groupName }}
										span.stat__title.nowrap-text(style='margin-left: 15px') {{ creatives.data.length + ' креативов' }}
								div(style='display: flex')
									.nowrap-text
										.stat__title На модерации
										.stat__total {{ creatives.data.filter((a) => a.approved === '').length }}
									.ml-5.nowrap-text
										.stat__title Одобрено
										.stat__total {{ creatives.data.filter((a) => a.approved === 'approve').length }}
									.ml-5.nowrap-text
										.stat__title Ошибка
										.stat__total {{ creatives.data.filter((a) => a.approved === "disapprove").length }}
						
						template(v-slot:header.actions='{ header }')
							popup-group(
								type='edit',
								:groupName='creatives.groupName',
								:items='creatives.data',
								:checkedPopup='creatives.data.map((a) => { return a.id; })',
								@refresh='refresh()'
							)
						
						template(v-slot:item.preview='{ item }')
							video-preview(:previewURL='item.previewURL', :id='item.id')
						template(v-slot:item.id='{ item }')
							div(style='display: flex; justify-content: space-between')
								.id-icon(@click='copyId(item.id)')
						template(v-slot:item.name='{ item }')
							v-btn.v-btn--table-link.v-btn--table-link--colored(
								outlined,
								small,
								:color='statusInfo(item.approved).color',
								:style='{ borderColor: statusInfo(item.approved).color + " !important" }',
								@click='openEditItemPopup(item)'
							) {{ item.name }}
						template(v-slot:item.size='{ item }')
							| {{ item.width + "х" + item.height }}
						template(v-slot:item.videoDuration='{ item }')
							| {{ item.videoDuration + "с" }}
						template(v-slot:item.pp='{ item }')
							| {{ publisherName(item) }}
						template(v-slot:item.approved='{ item }')
							div(v-if='item.approved === "approve"') Одобрено
							div(v-if='item.approved === ""') На модерации
							div(v-if='item.approved === "disapprove"', style='position: relative')
								tooltip(:text='item.reasonOfDisapproved || "Ошибка"')
									v-icon(
										color='red',
										style='position: absolute; left: -10px; top: 0px; transform: translateX(-100%)'
									)
										| info_outline
								| Ошибка
						
						template(v-slot:item.actions='{ item }')
							div(style='display: flex; align-items: right; justify-content: right')
								tooltip(text='Копировать ID оператора')
									.mr-4(
										v-if='item.publisher_creative_id !== ""',
										style='display: flex; justify-content: space-between'
									)
										.id-icon(@click='copyId(item.publisher_creative_id)')
								tooltip(text='Редактировать')
									v-btn(fab, x-small, outlined, @click='openEditItemPopup(item)', color='primary')
										v-icon(color='primary')
											| edit
								popup-copy(:name='item.name', :item='item', @refresh='refresh()')
								popup-deactivate(:name='item.name', :id='item.id', :creative='item', @refresh='refresh()')
								template(v-if='!item.state')
									popup-delete(:name='item.name', :id='item.id', :creative='item', @refresh='refresh()')
		popup-edit(v-model='editDialogState', :creative='currentCreative', @refresh='refresh()')
</template>

<script>
import Tooltip from '@/components/tooltip';
import PopupGroup from '@/components/popup/creative/editGroup';
import Upload from '@/components/popup/upload';
import PopupEdit from '@/components/popup/creative/editItem';
import PopupCopy from '@/components/popup/creative/copy';
import PopupDelete from '@/components/popup/creative/delete.vue';
import PopupDeactivate from '@/components/popup/creative/deactivate.vue';
import videoPreview from '@/components/creatives/videoPreview';
import eventPath from '@/mixins/eventPath';
import {mapActions, mapGetters} from 'vuex';
import {GET_CITIES, GET_PLATFORM_TYPES, GET_PUBLISHERS} from '@/store/const/map'
import {GET_CREATIVE_LIST} from '@/store/const/creatives';
import loading_mixin from '@/mixins/loading_mixin'

export default {
	name: 'Creatives',
	components: {
		PopupGroup,
		PopupEdit,
		PopupDelete,
		PopupDeactivate,
		PopupCopy,
		Tooltip,
		Upload,
		videoPreview,
	},
	mixins: [eventPath, loading_mixin],
	data() {
		return {
			editDialogState: false,
			currentCreative: {},
			dialog: false,
			type: '',
			search: '',
			showedTable: [],
			headers: [
				{
					value: 'preview',
					width: 60,
					sortable: false,
				},
				{
					text: 'ID',
					value: 'id',
					sortable: false,
				},
				{
					text: 'Имя',
					value: 'name',
					sortable: false,
					width: 350,
				},
				{
					text: 'Тип',
					value: 'surfaceType',
					align: 'center',
					sortable: false,
				},
				{
					text: 'Размер',
					value: 'size',
					align: 'center',
					sortable: false,
				},
				{
					text: 'Длительность',
					value: 'videoDuration',
					align: 'center',
					sortable: false,
				},
				{
					text: 'Модерация',
					value: 'approved',
					sortable: false,
				},
				{
					text: 'ПП',
					value: 'pp',
					align: 'center',
					sortable: false,
				},
				{
					value: 'actions',
					align: 'center',
					sortable: false,
					width: 160,
				},
			],
		};
	},
	computed: {
		...mapGetters('Map', ['getPublishers']),
		...mapGetters('Creatives', ['getTableData']),
	},
	async mounted() {
		this.setLoadingActions()
		this.GET_CITIES()
		this.GET_PLATFORM_TYPES();
		await this.GET_PUBLISHERS();
		if (!this.isError(GET_PUBLISHERS)) {
			this.GET_CREATIVE_LIST()
		}
	},
	methods: {
		...mapActions('Map', [GET_PLATFORM_TYPES, GET_PUBLISHERS, GET_CITIES]),
		...mapActions('Creatives', [GET_CREATIVE_LIST]),
		setLoadingActions() {
			this.actions = [
				GET_PLATFORM_TYPES,
				GET_PUBLISHERS,
				GET_CREATIVE_LIST
			]
		},
		openEditItemPopup(item) {
			this.currentCreative = item;
			this.editDialogState = true;
		},
		toggleTableShow(event, index, item) {
			let isButtonClick = false;
			if (isButtonClick) item.show = !item.show;
			let path = this.eventPath(event);
			path.forEach((a) => {
				if (a.classList) {
					if (a.classList.contains('v-data-table-header')) {
						const findedIndex = this.showedTable.indexOf(index);
						isButtonClick = true;
						findedIndex !== -1 ? this.showedTable.splice(findedIndex, 1) : this.showedTable.push(index);
					}
				}
			});
			if (isButtonClick) item.show = !item.show;
		},
		async refresh() {
			await this.GET_CREATIVE_LIST();
			this.dialog = false;
		},
		statusInfo(val) {
			switch (val) {
				case 'disapprove':
					return {
						text: 'Ошибка',
						color: '#FFB5AB',
					};
				case '':
					return {
						text: 'На модерации',
						color: '#DCDFE7',
					};
				case 'approve':
					return {
						text: 'Одобрено',
						color: '#4A55B0',
					};
			}
		},
		publisherName(item) {
			const publisher = this.getPublishers.find((a) => a.id === item.publisherID);
			return publisher ? publisher.name : '';
		},
		copyId(id) {
			this.$copyText(id)
			this.$notify({
				type: 'success',
				title: 'Успешно',
				text: 'Успешно скопировано',
			})
		},
	},
};
</script>
<style scoped>
.id-icon {
	width: 25px;
	height: 25px;
	background: url('~@/assets/SVG/icons/id-icon.svg') 50% no-repeat;
	cursor: pointer;
}

.no-data_text {
	color: #666666;
	font-size: 20px;
}
</style>
