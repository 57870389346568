<template lang="pug">
  v-card
    v-card-title.headline.grey.lighten-2
      .v-card-title--custom
        .upload-title Добавление креативов
        .field(style='width: 50%')
          .field__container
            .field__text Группа
            v-combobox(clearable, :items='getGroups', dense, outlined, hide-details, v-model='group')
        v-btn(fab, color='accent', x-small, outlined, @click='closePopup')
          v-icon close
    v-card-text
      .custom-scroll.mt-5(style='max-height: calc(100vh - 250px)')
        div(
          @drop.prevent='onDrop($event)',
          @dragover.prevent='dragover = true',
          @dragenter.prevent='dragover = true',
          @dragleave.prevent='dragover = false',
          :class='{ "grey lighten-2": dragover }'
        )
          div
            v-data-table.v-data-table--custome(
              no-data-text='',
              :headers='headers',
              :items='uploadedFiles',
              :expanded.sync='expanded',
              hide-default-footer,
              :hide-default-footer='uploadedFiles.length < 11',
              show-expand
            )
              template(v-slot:no-data)
                v-skeleton-loader(
                  v-if='isLoading',
                  type='table-row-divider@1, table-row'
                )
              template(v-slot:header.count='{ header }')
              template(v-slot:expanded-item='{ headers, item }')
                td(:colspan='headers.length')
                  div(style='max-width: 800px; padding: 0 20px')
                    v-row
                      v-col
                        .field
                          .field__container
                            .field__text Название
                            v-text-field(
                              v-model='item.name',
                              dense,
                              outlined,
                              hide-details='auto'
                            )
                          .field__container.mt-4
                            .field__text Поставщик поверхностей
                            v-autocomplete.elevation-0(
                              v-model="item.publisherID",
                              :items="publishersList",
                              @change='onPublisherChange($event, item)',
                              no-data-text='Нет подходящих результатов',
                              dense,
                              flat,
                              outlined,
                              height='36',
                              chips,
                              hide-details,
                              item-text="name",
                              item-value="id",
                              append-icon='mdi-chevron-down'
                            )
                          .field__container.mt-4
                            .field__text Город
                            v-select(
                              :items='allowedCities',
                              :menu-props='{ bottom: true, offsetY: true }',
                              no-data-text='Сначала выберите поставщика поверхностей',
                              dense,
                              outlined,
                              hide-details,
                              item-value='id',
                              item-text='name',
                              v-model='item.city',
                              append-icon='mdi-chevron-down'
                            )
                          .field__container.mt-4
                            .field__text Тип
                            v-select(
                              :items='getPlatformTypes',
                              :menu-props='{ bottom: true, offsetY: true }',
                              dense,
                              outlined,
                              hide-details,
                              item-value='id',
                              item-text='name',
                              v-model='item.surfaceType',
                              append-icon='mdi-chevron-down'
                            )
                          .field__container.mt-4
                            .field__text Длительность
                            v-text-field(
                              v-model='item.videoDurationFormatted',
                              dense,
                              outlined,
                              hide-details='auto',
                              disabled,
                              filled
                            )
                      v-col
                        .field
                          .field__container
                            .field__text Ширина
                            v-text-field(
                              v-model='item.width',
                              dense,
                              outlined,
                              hide-details='auto',
                              disabled,
                              filled
                            )
                          .field__container.mt-4
                            .field__text Высота
                            v-text-field(
                              v-model='item.height',
                              dense,
                              outlined,
                              hide-details='auto',
                              disabled,
                              filled
                            )
                          .field__container.mt-4
                            .field__text Рекламируемый бренд
                            v-text-field(
                              v-model='item.brand',
                              dense,
                              outlined,
                              hide-details='auto'
                            )
                          .field__container.mt-4
                            .field__text Теги через пробел
                            v-text-field(
                              v-model='item.tags',
                              dense,
                              outlined,
                              hide-details='auto'
                            )
                          .field__container.mt-4
                            .field__text Ссылка на источник
                            v-text-field(
                              v-model='item.remoteURL',
                              dense,
                              outlined,
                              hide-details='auto'
                            )

              template(v-slot:item.index='{ item }')
                | {{ uploadedFiles.indexOf(item) + 1 }}
              template(v-slot:item.preview='{ item }')
                video-preview(:previewURL='item.previewURL', :id='item.id')
              template(v-slot:item.action='{ item }')
                tooltip(text='Удалить')
                  v-btn(fab, outlined, @click='removeFile(item.name)', x-small, color='primary')
                    v-icon
                      | delete
              template(v-slot:item.info='{ item }')
                tooltip(text='Технические требования')
                  v-icon
                    | info
            v-row.d-flex.flex-column(
              v-if='!uploadedFiles.length && !isLoading',
              style='height: 320px; padding: 0 0 30px; background: #f5f7f8',
              dense='',
              align='center',
              justify='center'
            )
              v-icon(:class='[dragover ? "mt-2, mb-6" : "mt-5"]', size='60')
                | mdi-cloud-upload
              p(style='text-align: center')
                span(style='font-size: 20px') Переместите креативы
                br
                | или
              v-btn(@click='$refs.inputFile.value = "";$refs.inputFile.click()') Выберите файл
          input.v-btn(style='display: none', ref='inputFile', @change='onDrop($event)', type='file', multiple)
      .mt-5(v-if='uploadedFiles.length', style='text-align: right')
        v-btn(@click='uploadCreative', elevation='0', color='accent') Отправить на модерацию
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import loading_mixin from '@/mixins/loading_mixin';
import Tooltip from '@/components/tooltip';
import videoPreview from '@/components/creatives/videoPreview';
import {CREATE_CREATIVE, FILE_UPLOAD_CREATIVE} from '@/store/const/creatives';
import {GET_UNITS} from '@/store/const/map';

const loadVideo = (file) =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        resolve(this);
      };

      video.onerror = function () {
        reject('Invalid video. Please select a video file.');
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });

export default {
  name: 'Upload',
  components: {Tooltip, videoPreview},
  mixins: [loading_mixin],
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      group: '',
      name: '',
      publisherID: '',
      expanded: [],
      headers: [
        {
          text: '№',
          width: 50,
          sortable: false,
          value: 'index',
        },
        {
          text: 'Превью',
          value: 'preview',
          width: 50,
          sortable: false,
        },
        {
          text: 'Имя',
          value: 'fileName',
          width: 300,
          sortable: false,
        },
        {
          text: 'Тип',
          value: 'type',
          sortable: false,
        },
        {
          text: 'Размер',
          value: 'size',
          sortable: false,
        },
        {
          text: 'Длительность',
          value: 'videoDurationFormatted',
          sortable: false,
        },
        {
          text: 'Действие',
          width: 120,
          value: 'action',
          sortable: false,
        },
        {
          text: 'ТТ',
          width: 20,
          value: 'info',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
      allowedCities: [],
      dragover: false,
      uploadedFiles: [],
    };
  },
  computed: {
    ...mapGetters('Creatives', ['getGroups']),
    ...mapGetters('Map', ['getPublishers', 'getCities', 'getPlatforms', 'getPlatformTypes']),
    publishersList() {
      return this.getPublishers.sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  methods: {
    ...mapActions('Creatives', [FILE_UPLOAD_CREATIVE, CREATE_CREATIVE]),
    ...mapActions('Map', [GET_UNITS]),
    setLoadingActions() {
      this.actions = [
        FILE_UPLOAD_CREATIVE,
        CREATE_CREATIVE
      ]
    },
    closePopup() {
      this.uploadedFiles = [];
      this.group = '';
      this.name = '';
      this.publisherID = '';
      this.expanded = [];
      this.$emit('close');
    },
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex((file) => file.name === fileName);
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },
    bytesToMB(bytes) {
      return bytes / (1024 * 1024);
    },
    async onDrop(e) {

      this.dragover = false;
      let files = e.target.files || e.dataTransfer.files;
      if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
      if (!this.multiple && files.length > 1) {
        this.errorShow('Only one file can be uploaded at a time..');
      } else {
        for (let i = 0; i < files.length; i++) {
          let data = new FormData();
          let video;
          try {
            if (this.bytesToMB(files[i].size) > 10) {
              this.errorShow('Файл превышает допустимый размер в 10 МБ');
              continue;
            }
            video = await loadVideo(files[i]);
          } catch (error) {
            this.errorShow(error);
            return error;
          }
          data.append(`file`, files[i], files[i].name);
          const result = await this.FILE_UPLOAD_CREATIVE(data);
          await this.uploadFile(files[i], result.data, video.src);
          if (this.isError(FILE_UPLOAD_CREATIVE)) {
            this.errorShow(this.errorMessage(FILE_UPLOAD_CREATIVE))
          }
        }
      }
    },
    valid() {
      if (!this.group) {
        this.errorShow('Введите название группы');
        return false;
      }

      for (const a of this.uploadedFiles) {
        if (!a.name) {
          this.errorShow('Введите название креатива');
          return false;
        } else if (a.name.length < 3) {
          this.errorShow('В названии креатива дожно быть не менее 3-х символов');
          return false;
        }
        if (!a.publisherID) {
          this.errorShow('Вы не выбрали поставщика поверхности');
          return false;
        }
        if (a.publisherID === 'liqvid' && this.liqvidValidate(a) === false) {
          this.errorShow('Ошибка валидации размера креатива для Liqvid');
          return false;
        }
        if (!a.surfaceType) {
          this.errorShow('Вы не выбрали тип');
          return false;
        }
      }
      return true;
    },
    liqvidValidate({width, height}) {
      if (
        (width === 1080 && height === 1920) ||
        (width === 1920 && height === 1080) ||
        (width === 1080 && height === 1920) ||
        (width === 930 && height === 300) ||
        (width === 1280 && height === 480) ||
        (width === 1440 && height === 480) ||
        (width === 1920 && height === 1080)
      ) {
        return true;
      }
      return false;
    },
    async uploadCreative() {
      if (!this.valid()) return;
      for (const a of this.uploadedFiles) {
        let data = {
          name: a.name,
          publisherID: a.publisherID,
          fileID: a.fileID,
          surfaceType: a.surfaceType,
          city: a.city,
          group: this.group,
          remoteURL: a.remoteURL
        };
        await this.CREATE_CREATIVE(data)
        if (this.isError(CREATE_CREATIVE)) {
          this.errorShow(this.errorMessage(CREATE_CREATIVE));
          continue;
        }
        this.$notify({
          type: 'success',
          title: '',
          text: 'Креатив успешно создан',
        });
      }
      this.closePopup();
      this.$emit('refresh');
    },
    uploadFile(obj1, data, preview) {
      const obj2 = data;
      const result = {
        fileName: obj1.name,
        fileType: obj2.extension,
        videoDuration: obj2.duration,
        width: obj2.width,
        height: obj2.height,
        fileID: obj2.id,
        size: (obj2.size / 1000 / 1000).toFixed(1) + ' MB',
        videoDurationFormatted: obj2.duration + ' с',
        previewURL: preview,
      };
      this.uploadedFiles.push(result);
      this.expanded.push(result)
    },
    async onPublisherChange(value, item) {
      item.city = '';
      await this.GET_UNITS({publisherIds: [value]})
      const publishers = {};
      Object.values(this.getPublishers).forEach((i) => (publishers[i.id] = []));
      this.getPlatforms.forEach((platform) => {
        if (!publishers[platform.publisherId].includes(platform.city)) {
          publishers[platform.publisherId].push(platform.city);
        }
      });
      this.allowedCities = this.getCities.filter((city) => publishers[value].includes(city.id))
      .sort((a, b) => a.name.localeCompare(b.name));
    },
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-title {
  font-size: 32px;
  font-weight: 500;
}

.field {
  position: relative;
  padding-left: 100px;

  &__container {
    position: relative;
  }

  &__title {
    margin-top: 15px;
    font-size: 12px;
  }

  &__text {
    text-align: right;
    position: absolute;
    max-width: 95px;
    top: 50%;
    left: -15px;
    transform: translate(-100%, -50%);
    font-size: 12px;
  }
}
</style>
