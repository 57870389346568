var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"end"}},[_c('div',{staticClass:"center-block"},[_c('v-text-field',{attrs:{"label":"Поиск","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.getTableData.length)?_c('popup-group',{staticClass:"ml-4",on:{"refresh":function($event){return _vm.refresh()}}}):_vm._e(),_c('v-dialog',{attrs:{"width":"1150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"elevation":"0","color":"accent"}},'v-btn',attrs,false),on),[_vm._v("Загрузка креативов")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('upload',{attrs:{"multiple":true},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()}}})],1)],1)]),(!_vm.getTableData.length)?_c('v-row',{staticClass:"mt-5"},[_c('div',{staticClass:"text-center no-data_background pa-3"},[_c('div',{staticClass:"no-data_text"},[_vm._v("Вы ещё не создали ни одного креатива")])])]):_vm._e(),(_vm.getTableData.length)?_c('div',_vm._l((_vm.getTableData),function(creatives,index){return _c('v-row',{key:index,staticClass:"mt-5"},[_c('v-scroll-y-reverse-transition',[_c('v-data-table',{staticClass:"mt-5 v-data-table--custome mt-5",attrs:{"headers":_vm.headers,"items":creatives.data,"items-per-page":_vm.showedTable.indexOf(index) != -1 ? 100 : 0,"hide-default-footer":!creatives.show || creatives.data.length < 11,"search":_vm.search,"no-results-text":"Нет креативов соответствующих требованиям"},nativeOn:{"click":function($event){return _vm.toggleTableShow($event, index, creatives)}},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('div')]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('div',{staticClass:"stat__title"},[_vm._v(_vm._s(header.text))]),_c('div',{staticStyle:{"width":"170px"}},[_c('span',{staticClass:"stat__total"},[_vm._v(_vm._s(creatives.groupName))]),_c('span',{staticClass:"stat__title nowrap-text",staticStyle:{"margin-left":"15px"}},[_vm._v(_vm._s(creatives.data.length + ' креативов'))])])]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"nowrap-text"},[_c('div',{staticClass:"stat__title"},[_vm._v("На модерации")]),_c('div',{staticClass:"stat__total"},[_vm._v(_vm._s(creatives.data.filter(function (a) { return a.approved === ''; }).length))])]),_c('div',{staticClass:"ml-5 nowrap-text"},[_c('div',{staticClass:"stat__title"},[_vm._v("Одобрено")]),_c('div',{staticClass:"stat__total"},[_vm._v(_vm._s(creatives.data.filter(function (a) { return a.approved === 'approve'; }).length))])]),_c('div',{staticClass:"ml-5 nowrap-text"},[_c('div',{staticClass:"stat__title"},[_vm._v("Ошибка")]),_c('div',{staticClass:"stat__total"},[_vm._v(_vm._s(creatives.data.filter(function (a) { return a.approved === "disapprove"; }).length))])])])])]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('popup-group',{attrs:{"type":"edit","groupName":creatives.groupName,"items":creatives.data,"checkedPopup":creatives.data.map(function (a) { return a.id; })},on:{"refresh":function($event){return _vm.refresh()}}})]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('video-preview',{attrs:{"previewURL":item.previewURL,"id":item.id}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"id-icon",on:{"click":function($event){return _vm.copyId(item.id)}}})])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"v-btn--table-link v-btn--table-link--colored",style:({ borderColor: _vm.statusInfo(item.approved).color + " !important" }),attrs:{"outlined":"","small":"","color":_vm.statusInfo(item.approved).color},on:{"click":function($event){return _vm.openEditItemPopup(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.width + "х" + item.height))]}},{key:"item.videoDuration",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.videoDuration + "с"))]}},{key:"item.pp",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.publisherName(item)))]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [(item.approved === "approve")?_c('div',[_vm._v("Одобрено")]):_vm._e(),(item.approved === "")?_c('div',[_vm._v("На модерации")]):_vm._e(),(item.approved === "disapprove")?_c('div',{staticStyle:{"position":"relative"}},[_c('tooltip',{attrs:{"text":item.reasonOfDisapproved || "Ошибка"}},[_c('v-icon',{staticStyle:{"position":"absolute","left":"-10px","top":"0px","transform":"translateX(-100%)"},attrs:{"color":"red"}},[_vm._v("info_outline")])],1),_vm._v("Ошибка")],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"right","justify-content":"right"}},[_c('tooltip',{attrs:{"text":"Копировать ID оператора"}},[(item.publisher_creative_id !== "")?_c('div',{staticClass:"mr-4",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"id-icon",on:{"click":function($event){return _vm.copyId(item.publisher_creative_id)}}})]):_vm._e()]),_c('tooltip',{attrs:{"text":"Редактировать"}},[_c('v-btn',{attrs:{"fab":"","x-small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.openEditItemPopup(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("edit")])],1)],1),_c('popup-copy',{attrs:{"name":item.name,"item":item},on:{"refresh":function($event){return _vm.refresh()}}}),_c('popup-deactivate',{attrs:{"name":item.name,"id":item.id,"creative":item},on:{"refresh":function($event){return _vm.refresh()}}}),(!item.state)?[_c('popup-delete',{attrs:{"name":item.name,"id":item.id,"creative":item},on:{"refresh":function($event){return _vm.refresh()}}})]:_vm._e()],2)]}}],null,true)})],1)],1)}),1):_vm._e(),_c('popup-edit',{attrs:{"creative":_vm.currentCreative},on:{"refresh":function($event){return _vm.refresh()}},model:{value:(_vm.editDialogState),callback:function ($$v) {_vm.editDialogState=$$v},expression:"editDialogState"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }