<template lang="pug">
  div
    v-dialog(v-model='removeDialog', width='900')
      template(v-slot:activator='{ on, attrs }')
        tooltip(text='Удалить')
          v-btn.ml-4(fab, color='primary', x-small, v-bind='attrs', v-on='on', outlined)
            v-icon(color='red')
              | close
      v-card(v-model='removeDialog')
        v-card-title.headline.grey.lighten-2
          div Вы уверены что хотите удалить "{{ name }}" безвозвратно?
        v-card-text.mt-5
          .mt-5(style='display: flex; justify-content: flex-end')
            v-btn(@click='removeDialog = false', elevation='0', outlined, color='accent')
              | Отмена
            v-btn.ml-4(@click='del', elevation='0', color='red', style='color: #fff')
              | Удалить
</template>

<script>
import {mapActions} from 'vuex';
import Tooltip from '@/components/tooltip';
import loading from '@/mixins/loading_mixin';
import {DELETE_CREATIVE} from '@/store/const/creatives';

export default {
  name: 'Copy',
  components: {Tooltip},
  mixins: [loading],
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    creative: {
      type: Object,
    },
  },
  data() {
    return {
      removeDialog: false,
    };
  },
  methods: {
    ...mapActions('Creatives', [DELETE_CREATIVE]),
    async del() {
      let data = {
        id: this.id,
      };
      await this.DELETE_CREATIVE(data);
      if (this.isError(DELETE_CREATIVE)) {
        this.errorShow('Не удалось удалить');
        return
      }
      this.$notify({
        type: 'success',
        title: 'Успешно',
        text: 'Креатив успешно удален',
      });
      this.removeDialog = false;
      this.$emit('refresh');
    },
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
  },
};
</script>

<style scoped></style>
