<template lang="pug">
  v-dialog(v-model='dialog', width='1000')
    template(v-slot:activator='{ on, attrs }')
      tooltip(text='Деактивировать')
        v-btn.ml-4(fab, color='primary', x-small, v-bind='attrs', v-on='on', outlined)
          v-icon(color='red')
            | mdi-cancel
    v-card(v-model='dialog')
      v-card-title.headline.grey.lighten-2
        div Вы уверены что хотите отвязать креатив "{{ name }}" для всех кампаний?
      v-card-text.mt-5
        .mt-5(style='display: flex; justify-content: flex-end')
          v-btn(@click='dialog = false', elevation='0', outlined, color='accent')
            | Отмена
          v-btn.ml-4(@click='deactivate', elevation='0', color='red', style='color: #fff')
            | Деактивировать
</template>

<script>
import {mapActions} from 'vuex';
import loading from '@/mixins/loading_mixin';
import Tooltip from '@/components/tooltip';
import {DEACTIVATE_CREATIVE} from '@/store/const/creatives';

export default {
  name: 'Deactivate',
  components: {Tooltip},
  mixins: [loading],
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    creative: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions('Creatives', [DEACTIVATE_CREATIVE]),
    async deactivate() {

      let data = {
        id: this.id,
      };
      await this.DEACTIVATE_CREATIVE(data);
      if (this.isError(DEACTIVATE_CREATIVE)) {
        this.errorShow('Не удалось отвязать креатив');
        return;
      }
      this.$notify({
        title: 'Успешно',
        text: 'Креатив успешно отвязан от кампаний',
      });
      this.dialog = false;
      this.$emit('refresh');
    },
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
  },
};
</script>

<style scoped></style>
