<template lang="pug">
  div
    v-dialog(v-model='dialog', width='900')
      template(v-slot:activator='{ on, attrs }')
        v-btn(elevation='0', v-if='type === "new"', color='accent', v-bind='attrs', v-on='on')
          | Новая группа

        tooltip(text='Редактировать группу')
          v-btn(elevation='0', v-if='type !== "new"', fab, x-small, v-bind='attrs', v-on='on')
            v-icon(small) mdi-lead-pencil
      v-card(v-model='dialog')
        v-card-title.headline.grey.lighten-2
          div {{ type === 'new' ? 'Новая группа' : 'Редактирование группы' }}
        v-card-text.mt-5
          v-row
            v-col
              v-text-field(label='Поиск', v-model='search', dense, outlined, hide-details)
            v-col
              v-text-field(label='Придумайте название', v-model='name', dense, outlined, hide-details)

          v-row(justify='space-between', align='end', v-for='(item, index) in getCreativeList' :key='index')
            v-checkbox.mt-2.ml-2(
              color='accent',
              multiple,
              dense,
              hide-details,
              @change="item"
              v-model='selected'
              :value='item.id',
              :label='item.name',
              :id='index.id',
            ) {{ item.name }}
          v-card-actions
            v-spacer
            v-btn(color='accent', @click='update', elevation='0', width='140')
              | {{ type === "new" ? "Создать группу" : "Сохранить изменения" }}
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Tooltip from '@/components/tooltip';
import {UPDATE_CREATIVE} from '@/store/const/creatives';

export default {
  name: 'PopupGroup',
  components: {Tooltip},
  computed: {
    ...mapGetters('Creatives', ['getCreativeList']),
  },
  props: {
    type: {
      type: String,
      default: 'new',
    },
    groupName: {
      type: String,
      default: '',
    },
  },
  watch: {
    dialog: {
      handler(newVal, oldVal) {
        if (newVal && !oldVal) {
          this.selected = this.getCreativeList
          .filter((a) => a.group === this.groupName)
          .map((a) => {
            return a.id;
          });
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      search: '',
      name: this.groupName,
      dialog: false,
      selected: [],
    };
  },
  mounted() {
    this.selected = this.getCreativeList
    .filter((a) => a.group === this.groupName)
    .map((a) => {
      return a.id;
    });
  },
  methods: {
    ...mapActions('Creatives', [UPDATE_CREATIVE]),
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
    valid() {
      if (!this.name) {
        this.errorShow('Введите название группы');
        return false;
      }
      if (!this.selected.length) {
        this.errorShow('Выберите хотя бы один креатив');
        return false;
      }

      return true;
    },
    async update() {
      if (!this.valid()) return;
      for (const a of this.selected) {
        let item = this.getCreativeList.filter((b) => b.id === a)[0];
        let data = {
          group: this.name,
          id: item.id,
          name: item.name,
          publisherID: item.publisherID,
          fileID: item.fileID,
          ownerID: item.ownerID,
          surfaceType: item.surfaceType,
          city: item.city,
        };
        await this.UPDATE_CREATIVE(data)
      }
      this.dialog = false;
      await this.$emit('refresh');
    },
  },
};
</script>

<style scoped></style>
